
import { Component, Vue, Prop } from 'nuxt-property-decorator'
import { mask } from 'vue-the-mask'
import BaseInput from '~/components/UI/Input/BaseInput.vue'

@Component({
  components: { BaseInput },
  directives: {
    mask
  }
})
export default class InputDate extends Vue {
  @Prop({ type: String, default: '' }) value!: string
  @Prop({ type: String, default: 'Дата рождения<span>, например 20.12.1978</span>' }) label!: string
}
