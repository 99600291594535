const autoTranslate = (v) => {
  const engLetters = 'ABEKMHOPCTYXabekmhopctyx'
  const translations = {
    A: 'А',
    B: 'В',
    E: 'Е',
    K: 'К',
    M: 'М',
    H: 'Н',
    O: 'О',
    P: 'Р',
    C: 'С',
    T: 'Т',
    Y: 'У',
    X: 'Х'
  }
  if (engLetters.includes(v)) {
    return translations[v.toLocaleUpperCase()]
  }
  return v.toLocaleUpperCase()
}
export const maskTokens = {
  '#': { pattern: /\d/ },
  f: {
    pattern: /[АВЕКМНОРСТУХавекмнорстухABEKMHOPCTYXabekmhopctyx]/,
    transform: autoTranslate
  },
  F: {
    pattern: /[0-9АВЕКМНОРСТУХавекмнорстухABEKMHOPCTYXabekmhopctyx]/,
    transform: autoTranslate
  }
}
