<template>
  <div
    class="base-form-field"
    :class="{
      '--has-value': value || value === 0 ,
      '--error': error
    }"
  >
    <the-mask
      v-if="mask"
      :id="id"
      ref="input"
      type="text"
      :value="value"
      v-bind="attrs"
      :mask="mask"
      :tokens="maskTokens"
      v-on="listeners"
    />
    <input
      v-else
      :id="id"
      ref="input"
      type="text"
      :value="value"
      v-bind="attrs"
      v-on="listeners"
    >
    <label
      :for="id"
      class="base-form-field__label"
      v-html="label"
    />
    <error-message v-if="showErrorText && error && errorMessage">
      {{ errorMessage }}
    </error-message>
    <div v-else-if="inputTip" class="base-form-field__text-tip" v-html="inputTip" />
  </div>
</template>

<script>
import { TheMask } from 'vue-the-mask'
import ErrorMessage from '~/components/UI/ErrorMessage.vue'
import { maskTokens } from '~/helpers/maskTokens'

export default {
  name: 'BaseInput',
  components: {
    ErrorMessage,
    TheMask
  },
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number],
      default: ''
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    inputTip: {
      type: String,
      default: ''
    },
    error: {
      type: Boolean,
      required: false,
      default: false
    },
    showErrorText: {
      type: Boolean,
      required: false,
      default: true
    },
    errorMessage: {
      type: String,
      required: false,
      default: ''
    },
    mask: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      maskTokens
    }
  },
  computed: {
    listeners () {
      return {
        ...this.$listeners,
        input: (event) => {
          // The mask component event already is value
          return ['string', 'number'].includes(typeof event)
            ? this.updateValue(event)
            : this.updateValue(event.target.value)
        },
        blur: (e) => {
          this.updateValue(e.target.value.trim())
          this.$emit('blur', e.target.value.trim())
        }
      }
    },
    attrs () {
      return { ...this.$attrs, class: 'base-form-field__input' }
    }
  },
  methods: {
    updateValue (value) {
      this.$emit('input', value)
    },
    focus () {
      this.$refs.input.$el.focus()
    }
  }
}
</script>

<style lang="scss" scoped>
@mixin labelTransform {
  transform: scale(0.6666) translateY(-8px);
  width: calc(100% * 1.33);

  &::v-deep span {
    display: inline;
  }
}

.base-form-field {
  position: relative;
  width: 100%;

  &__label {
    color: $color-text-primary-t60;
    font-size: 18px;
    font-weight: 400;
    left: 19px;
    line-height: 27px;
    pointer-events: none;
    position: absolute;
    top: 16px;
    transform: scale(1) translateY(0);
    transform-origin: top left;
    transition: transform .25s ease, width .25s;
    white-space: nowrap;
    width: 90%;
    overflow: hidden;

    &::v-deep span {
      display: none;
    }
  }

  &__input {
    appearance: none;
    background: rgba(9, 132, 227, 0.05);
    border: 1px solid rgba(9, 132, 227, 0.35);
    border-radius: 4px;
    box-shadow: none;
    font-size: 18px;
    font-weight: 400;
    height: 60px;
    letter-spacing: .2px;
    line-height: 27px;
    padding: 24px 19px 6px;
    transition: box-shadow, background-color .25s ease;
    width: 100%;

    &:hover,
    &:focus {
      background: white;
      outline: none;
    }

    &:invalid {
      border: 1px solid #F3454C;
      background: rgba(243, 69, 76, .1);
    }

    &:focus + .base-form-field__label {
      @include labelTransform
    }

    &::placeholder {
      color: #fff;

      &:focus {
        color: transparent;
      }
    }

    //&:focus {
    //  &::-webkit-input-placeholder,
    //  &:-moz-placeholder,
    //  &::-moz-placeholder,
    //  &:-ms-input-placeholder {
    //    color: transparent;
    //  }
    //}
    //&::-webkit-input-placeholder,
    //&:-moz-placeholder,
    //&::-moz-placeholder,
    //&:-ms-input-placeholder {
    //  color: #fff;
    //}

    &::-webkit-search-cancel-button,
    &::-webkit-contacts-auto-fill-button {
      display: none !important;
      pointer-events: none;
      position: absolute;
      right: 0;
      visibility: hidden;
    }

    &::-ms-clear {
      display: none;
    }
  }

  &.--has-value .base-form-field__label {
    @include labelTransform
  }

  &.--error {
    .base-form-field__input {
      border: 1px solid $error-color;
      background: rgba(243, 69, 76, .1);
    }

    .base-form-field__label {
      color: $error-color;
    }
  }

  &__text-tip,
  &__error-msg {
    padding-top: 4px;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
  }

  &__text-tip {
    color: #a9b1b8;
  }

  &__error-msg {
    color: $error-color;
  }
}

</style>
