<template>
  <div class="error-msg">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ErrorMessage'
}
</script>

<style lang="scss" scoped>
.error-msg {
  color: $error-color;
  padding-top: 4px;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
}
</style>
